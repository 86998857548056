<template>
  <div class="footer-component">
    <v-row dense>
      <v-col class="f-c-col" cols="6" sm="6" md="3" lg="3">
        <span class="f-text-left">Designed Using: &nbsp;</span>
        <span class="f-text-right">ADOBE XD</span>
      </v-col>
      <v-col class="f-c-col" cols="6" sm="6" md="3" lg="3">
        <span class="f-text-left">Built Using: &nbsp;</span>
        <span class="f-text-right">Vue.js</span>
      </v-col>
      <v-col class="f-c-col" cols="6" sm="6" md="3" lg="3">
        <svg class="copyright_svg" width="20" height="20" viewBox="0 0 20 20">
          <g id="noun_copyright_98976" transform="translate(1 135)">
            <path id="Path_2572" data-name="Path 2572"
              d="M15,5A10,10,0,1,0,25,15,10,10,0,0,0,15,5ZM12.643,17.357a3.337,3.337,0,0,0,4.714,0l1.571,1.571a5.556,5.556,0,1,1,0-7.857l-1.571,1.571a3.333,3.333,0,1,0-4.714,4.714Z"
              transform="translate(-6 -140)" />
          </g>
        </svg>
        <span class="f-text-left">&nbsp;2021 Naveen &nbsp;</span>
        <span class="f-text-right">DB</span>
      </v-col>
      <v-col class="f-c-col" cols="6" sm="6" md="3" lg="3">
        <svg class="f-c-scrollup" width="35" height="35" viewBox="0 0 35 35" @click="onClickScrollTop">
          <path id="up"
            d="M31.479,14.021a17.5,17.5,0,1,0,17.5,17.5A17.507,17.507,0,0,0,31.479,14.021Zm0,.972A16.528,16.528,0,1,1,14.951,31.521,16.52,16.52,0,0,1,31.479,14.994Zm0,1.458a15.07,15.07,0,1,0,10.129,3.912.243.243,0,1,0-.327.36,14.522,14.522,0,1,1-4.781-2.9.243.243,0,1,0,.167-.456A15.045,15.045,0,0,0,31.479,16.452Zm6.553,1.526a.243.243,0,0,0-.1.462q.488.242.956.518a.243.243,0,1,0,.247-.418q-.483-.285-.987-.535A.243.243,0,0,0,38.032,17.978Zm1.958,1.154a.243.243,0,0,0-.143.444c.162.113.322.231.479.35a.243.243,0,0,0,.3-.386c-.162-.124-.328-.245-.495-.362a.243.243,0,0,0-.136-.047Zm-8.5,4.117a.97.97,0,0,0-.687.284L25.94,28.393a.972.972,0,0,0,1.375,1.375l3.2-3.2V38.8a.972.972,0,1,0,1.944,0V26.566l3.2,3.2a.972.972,0,1,0,1.375-1.375l-4.861-4.861A.97.97,0,0,0,31.489,23.248Zm0,.483a.485.485,0,0,1,.344.144l4.861,4.861a.486.486,0,1,1-.687.687l-4.031-4.031V38.8a.486.486,0,1,1-.972,0V25.393l-4.031,4.031a.486.486,0,0,1-.687-.687l4.861-4.861A.485.485,0,0,1,31.489,23.731Z"
            transform="translate(-13.979 -14.021)" />
        </svg>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    onClickScrollTop() {
      const el = document.getElementById('appContainer');
      el.scrollIntoView({ behavior: "smooth" });
    }
  }
}
</script>

<style lang="scss" >
:root {
  --app-footer-bg: rgba(108, 99, 255, 0.15);
  --app-footer-text-left: #223464;
  --app-footer-text-right: #882724fc;
  --app-footer-copyright-color: #ec615b;
  --app-footer-scrollup-color: #223464;
}

:root.dark-theme {
  --app-footer-bg: #373840;
  --app-footer-text-left: #F5E9DB;
  --app-footer-text-right: #F8BF7B;
  --app-footer-copyright-color: #F8BF7B;
  --app-footer-scrollup-color: #FFFFFF;
}

.footer-component {
  height: 100px;
  background-color: var(--app-footer-bg);
  padding: 16px;
  display: flex;
  align-items: center;

  .f-c-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .f-text-left {
    color: var(--app-footer-text-left);
    font-weight: 600;
  }

  .f-text-right {
    color: var(--app-footer-text-right);
    font-weight: 600;
  }

  .f-c-scrollup {
    cursor: pointer;
  }

  .f-c-scrollup:hover path {
    color: #e85f59;
  }

  .copyright_svg path {
    fill: var(--app-footer-copyright-color);
  }

  .f-c-scrollup path {
    fill: var(--app-footer-scrollup-color);
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .footer-component {
    font-size: 14px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .footer-component {
    font-size: 12px;
  }
}
</style>